var web3providerOptions = {
  injected: {
    display: {
      name: "MetaMask",
      description: "Connect with the metamask from your Browser",
    },
    package: null,
  },
};

export default web3providerOptions;
