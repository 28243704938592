import { Routes, Route } from "react-router-dom";
import { Layout, Row, Col } from "antd";

import Home from "./views/Home";
import styled from "styled-components";
import { useEffect } from "react";

import web3providerOptions from "./configs/web3providers";
import Web3Modal from "web3modal";

const MzrLayout = styled(Layout)`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
      180deg,
      rgba(1, 22, 39, 0.1),
      rgba(1, 22, 39, 0.1)
    ),
    url("./assets/background.png") no-repeat center center fixed;
  background-size: cover;
  background-position: center;
`;

function App() {
  //const [isConnectedWallet, setIsConnectedWallet] = useState(false);

  async function checkWeb3Auth() {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: web3providerOptions, // required
    });

    if (web3Modal.providerController.cachedProvider === "") {
      //setIsConnectedWallet(false);
    } else {
      //setIsConnectedWallet(true);
    }
  }

  useEffect(() => {
    checkWeb3Auth();
  }, []);

  return (
    <MzrLayout>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </MzrLayout>
  );
}

export default App;
