import { Button, Col, Row } from "antd";
import MzrButton from "../components/shared/MzrButton";

import web3providerOptions from "../configs/web3providers";
import { BigNumber, ethers } from "ethers";

import Web3Modal from "web3modal";
import { useState } from "react";

import usdc from "../contracts/usdc";
import nft from "../contracts/nft";
import MzrLoading from "../components/shared/MzrLoading";

import appConfig from "../configs/app";

import { toHex } from "../utils";

function Home() {
  const [isProcessing, setProcessing] = useState(false);
  const [isMinted, setMinted] = useState(false);
  const [txUrl, setTxUrl] = useState('');

  const network = appConfig.network; // 5 for goerli, 1 for mainnet

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
    } catch (switchError) {
      console.log(switchError);
      console.log(
        `Error switching network to ${network === 5 ? "Goerli" : "Mainnet"}`
      );
    }
  };

  async function mint() {
    if (isMinted) {
      window.open(txUrl, '_blank').focus();
      return;
    }
    await switchNetwork();

    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: web3providerOptions, // required
    });

    var instance;

    try {
      instance = await web3Modal.connect();
    } catch (e) {
      console.error(e);
    }

    let provider = new ethers.providers.Web3Provider(instance);

    const signer = provider.getSigner();

    const usdtContractInstance = new ethers.Contract(
      appConfig.contract,
      usdc.abi,
      signer
    );

    var value = BigNumber.from(Math.pow(10, 6).toString()).mul(
      BigNumber.from(appConfig.value)
    );

    var spender = appConfig.spender;

    setProcessing(true);

    let allowance = await usdtContractInstance.allowance(signer.getAddress(), appConfig.spender);
    if (allowance.lt(value)) {
      let approveTransaction = await usdtContractInstance.approve(spender, value, {
        gasLimit: 100000
      });
      await waitUntilTransactionConfirmed(provider, approveTransaction.hash);
    }

    const kurkContractInstance = new ethers.Contract(spender, nft.abi, signer);

    setProcessing(true);

    let mintTransaction = await kurkContractInstance.mint({
      gasLimit: 300000
    });

    const tx = await waitUntilTransactionConfirmed(provider, mintTransaction.hash);
    setTxUrl(`${network === 1 ? 'https://etherscan.io/tx/': 'https://goerli.etherscan.io/tx/'}${tx.hash}`);
    setMinted(true);
  }

  async function getTransaction(provider, hash) {
    return await provider.getTransaction(hash);
  }

  async function waitUntilTransactionConfirmed(provider, hash) {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        getTransaction(provider, hash).then((transaction) => {
          console.log(transaction);

          if (transaction.confirmations >= 1) {
            resolve(transaction);
            setProcessing(false);
            clearInterval(interval);
          }
        });
      }, 1000);
    });
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
            height: "100vh",
            align: "center",
          }}
        >
          <div style={{ alignSelf: "center", marginTop: "10%" }}>
            <MzrButton
              text={ isProcessing ? "Processing..." : (isMinted ? "Open on etherscan" : "Mint")}
              isDisabled={isProcessing}
              onClick={() => {
                if (!isProcessing) {
                  mint();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
