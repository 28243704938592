import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import appConfig from "../../configs/app";


const Loading = styled(Col)`
  height: ${(props) => props.height};
  background-color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
`;

function MzrLoading({ text, height, onClick }) {
  return (
    <MzrLoading
      height={height}
      color={appConfig.theme.primaryColor}
      onClick={(e) => {
        onClick();
      }}
    >
      <Row
        justify="center"
        style={{
          height: "100%",
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Col
          style={{
            alignSelf: "center",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: 16,
          }}
        >
          <span className="text-center">{text}</span>
        </Col>
      </Row>
    </MzrLoading>
  );
}

export default MzrLoading;
